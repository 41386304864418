<template>
  <div>
<!--page1-->
    <div class="flex">
      <div class="w-1/2 h-screen">
        <img src="../assets/HomePics/1.png" class="h-full">
      </div>
      <div class="w-1/2 bg-white  grid place-items-center  overflow-x-hidden">
        <div class=" w-full h-full ">
          <div class="w-full h-3/5 flex items-end  relative">
            <div class="pb-24 ">
              <div class="" >
                <img src="../assets/HomePics/HYScitech-BG.svg" alt="">
              </div>
              <transition >
                  <div class="-mt-12 " v-show="show">
                  <div class="ability ">在高新领域里，</div>
                  <div class="ability">我们拥有什么能力？</div>
                  <div class=" tracking-widest  w-5/6 text-gray-500 pl-12">
                  深圳市华谕电子科技信息有限公司成立于2015年，
                    是业内先进的、以移动音视频应用和流媒体服务为核心技术的信息化解决方案及信息技术服务商。
                  </div>
                </div>
              </transition>
              <div class="h-5 w-2/3  float-right absolute bottom-0 right-0" style="background: rgba(39,131,184,0.03);"></div>
            </div>
          </div>
          <div class="w-full 2xl:h-2/5 lg:4/5 flex items-center ">
            <transition name="slide">
            <div v-show="show">
                <div class=" tracking-widest  w-3/5 text-gray-500 pl-12  ">
                  作为国家高新技术企业公司之一，
                  华谕科技提供可视化指挥调度平台SDK和以移动可视化终端为主的硬件终端设备APK融合解决方案。
                  重点聚焦智慧警务、智慧政务、数字政府服务、可视化调服及公共安全大数据等应用集成领域的信息化市场，
                  同时积极拓展企业服务。
                </div>
            </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
<!--page2-->
    <div class="w-full bg-black  pt-36 pb-4 overflow-auto">
      <div class=" h-full w-full  flex">
       <div class="pt-20 w-2/5 h-full  ">
         <img src="../assets/HomePics/HYScitech-BG2.svg" alt="华谕" class="ml-6  ">
         <transition>
           <div class="2xl:pl-48 lg:pl-4 tablet:pl-8 " v-show="show2">
            <transition name="slidex">
             <div v-show="nowLabel===0">
                <div class="text-5xl text-white tracking-wider leading-normal  -mt-14 pb-16 text-left ">
                <h1>   构建现代信息化 <br>
                <div class="flex  inline-flex">应急救援体系<div class=" rounded-full mt-12 ml-1 " style="background-color: #1E78FF;width: 12px ;height: 12px"></div></div> </h1>
                  </div>
                <div class="text-white leading-6  text-left tracking-widest pb-36">
           我们通过大数据思维结合消防管理制度<br />
           打造以数据为核心要素平战结合的“千里眼”、“顺风耳”
         </div>
                <div class="">
                 <div class="grid place-items-left">
                   <div class="flex">
               <span class=" text-2xl text-white font-serif font-bold cursor-pointer hover:text-blue-200 "
                     @click="goPage2('/solvePlan',)">
             查看方案
           </span>
                     <div class="goPage"
                          @click="goPage2('/solvePlan',)">
                       <img src="../assets/HomePics/jump.svg" alt="">
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </transition>
            <transition name="slidex">
             <div v-show="nowLabel===1">
                <div class="text-5xl text-white tracking-wider leading-normal  -mt-14 pb-16 text-left ">
                <h1>   集成融合新一代 <br>
                <div class="flex  inline-flex">可视化云平台<div class=" rounded-full mt-12 ml-1 " style="background-color: #1E78FF;width: 12px ;height: 12px"></div></div> </h1>
                  </div>
                <div class="text-white leading-6  text-left tracking-widest pb-36">
           华谕科技自主研发的可视化指挥调度平台<br />
           以“实时视频监控+音视频数字融合+融合指挥调度”为核心。
         </div>
               <div class="">
                 <div class="grid place-items-left">
                   <div class="flex">
               <span class=" text-2xl text-white font-serif font-bold cursor-pointer hover:text-blue-200 "
                     @click="goPage2('/solvePlan',)">
             查看方案
           </span>
                     <div class="goPage"
                          @click="goPage2('/solvePlan',)">
                       <img src="../assets/HomePics/jump.svg" alt="">
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </transition>
          </div>
         </transition>
       </div>
       <div class=" 2xl:w-3/5 lg:w-4/5 tablet:w-2/3 h-full relative overflow-x-hidden">
          <el-carousel indicator-position="none" :interval="10000000" @change="changePic"
                       height="800px"  ref="carousel" arrow="never">
            <el-carousel-item v-for="item in picData" :key="item.name" >
              <img :src="item.picSource" >
              <div class="  mt-4 inline-flex relative" style="width: 780px">
                <img src="../assets/HomePics/left.svg" class="absolute left-0" @click="$refs.carousel.prev()" >
                <div class="w-full  text-center text-white text-lg tracking-wider" >{{item.name}}</div>
                <img src="../assets/HomePics/right.svg" class="absolute right-0" @click="$refs.carousel.next()">
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="absolute top-0  w-full" style="left: 820px;">
            <img :src=picSrc class=" object-cover filter brightness-75 ">
          </div>
        </div>
      </div>
    </div>
<!--联系我们-->
    <div class="h-96 relative  " >
      <img src="../assets/HomePics/bottom2.png" class="h-full w-full ">
      <div class=" absolute top-1/3 w-full ">
        <transition>
          <div v-show="contact">
            <div class="w-full h-1/2  text-white  text-center pt-4 mb-4  " style="font-size: 36px">
            选择华谕 开启数字化转型
            </div>
          <div class=" w-full h-1/2   justify-center flex "  >
         <span class=" text-white text-lg border  w-36 justify-center flex my-4 py-2 hover:bg-gray-600
                font-light rounded cursor-pointer" @click="goPage('/contactUs')" >
            联系我们
         </span>
          </div>
        </div>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data(){
    return{
      show:false,
      show2:false,
      picData:[
        {name:'可视化指挥调度系统',picSource:require('../assets/HomePics/zhihui2.png')},
        {name:'消防应急救援作战指挥调度系统',picSource:require( '../assets/HomePics/dallas-penner-rjtWAHo61As-unsplash.png')},
      ],
      picSrc:require('../assets/HomePics/dallas-penner-rjtWAHo61As-unsplash.png'),
      nowLabel:1,
      contact:true
    }
  },
  mounted() {
    if (window.innerHeight>1100){
      this.show= true
      this.show2 =true
    }
    window.addEventListener('scroll', this.scrolling) // 监听页面滚动
    this.$store.commit('setMenuColor','')
    this.$store.commit('setMenuIndex','1')
    this.show =true
    this.show2 =true
  },
  watch:{
    nowLabel:function (){
    }
  },
  methods:{
    goPage(page){
      this.$store.commit('setMenuIndex','6')
      this.$router.push(page)
      window.scrollTo(0,0)
    },
    goPage2(){
      this.$store.commit('setMenuIndex','2')
      this.$router.push({name:'solvePlan',query:{pageOne:this.nowLabel}})
      window.scrollTo(0,0)
    },
    changePic(a){
      if (a===0){
        this.picSrc=require("../assets/HomePics/dallas-penner-rjtWAHo61As-unsplash.png")
        this.nowLabel = 1
      }else{
        this.picSrc=require("../assets/HomePics/zhihui2.png")
        this.nowLabel = 0
      }
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
          document.body.scrollTop
      if (scrollTop<700&&window.innerHeight<1100){
        this.show= true
        this.show2 =false
      }else if(window.innerHeight<1100){
        this.show = false
        this.show2 =true
      }
    }
  }
}
</script>

<style scoped>
.ability{
  @apply 2xl:text-5xl lg:text-4xl tablet:text-4xl font-semibold tracking-widest mb-6 pl-12
}
.goPage{
  @apply  ml-14   -mt-2 cursor-pointer
}

.v-enter{
  opacity:0;
  transform: translateY(-30px);
}
.v-leave-to{
  opacity: 0;
  transform: translateY(30px);
}
.v-enter-active{
  transition: all 1.5s;
}
.v-leave-active{
  transition: all 2s;
}
.slide-enter{
  opacity:0;
  transform: translateY(30px);
}
.slide-enter-active{
  transition: all 2s;
}
.slidex-enter{
  opacity:0;
  transform: translateX(30px);
}
.slidex-enter-active{
  transition: all 1.5s;
}
</style>
