<template>
  <div class="w-full h-16 fixed flex z-50 "  id="header" :style="$store.state.style">
<!--    <img src="../assets/HomePics/LOGO-B.svg"  class=" ml-10  my-2 " style="color: red">-->
    <svg data-v-19e5a669=""  aria-hidden="true" class="svg-icon ml-10   h-16 w-36"><use data-v-19e5a669="" xlink:href="#icon-logo"></use></svg>
    <div class="  flex-1 place-self-center">
      <ul class="inline-flex float-right mx-6  " style="font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;font-weight: normal;" >
        <li class="mx-4 text-center"  @click="goPage('/homeView','1')" ><a
            href="#">首页</a>
          <div :class="[$store.state.menuIndex ==='1'?'inItem':'liItem']" ></div>
        </li>
        <li  class="mx-4 text-center"  @click="goPage('/solvePlan','2')"><a
            href="#">解决方案</a>
          <div :class="[$store.state.menuIndex ==='2'?'inItem':'liItem']"></div>
        </li>
<!--        <li class="mx-4 text-center"   @click="goPage('/computePrice','3')"><a-->
<!--            href="#">价格计算</a>-->
<!--          <div :class="[$store.state.menuIndex ==='3'?'inItem':'liItem']" ></div>-->
<!--        </li>-->
<!--        <li class="mx-4 text-center"  @click="goPage('/helpInfo','4')">-->
<!--          <a href="#">帮助</a>-->
<!--          <div :class="[$store.state.menuIndex ==='4'?'inItem':'liItem']" ></div>-->
<!--        </li>-->
        <li class="mx-4 text-center"  @click="goPage('/aboutUs','5')"><a href="#">关于</a>
          <div :class="[$store.state.menuIndex ==='5'?'inItem':'liItem']" ></div></li>
        <li  class="mx-4 text-center"  @click="goPage('/contactUs','6')"><a
            href="#">联系我们</a>
          <div :class="[$store.state.menuIndex ==='6'?'inItem':'liItem']"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "webHeader",
  data(){
    return{
      pageNum:'1',
      top:1
    }
  },
  mounted() {

    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods:{
    goPage(page,num){
      this.$store.commit('setMenuIndex',num)
      this.$router.push(page)
      window.scrollTo(0,0)
      this.pageNum = this.$store.state.menuIndex
      
      if (num==='5'||num==='2'||num==='6'){
        
        this.$store.commit('setMenuColor','white')
        
      }else{
        this.$store.commit('setMenuColor','')
      }

    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop<400 &&this.top === 1 ){
        document.getElementById("header").style.color = this.$store.state.style.color
        document.getElementById("header").style.background = "none"
        document.getElementById("header").style.boxShadow = "none"
        //document.getElementById("header").style.opacity = "1"
        this.top = 2
      }else if(this.top === 2 && scrollTop>=400 ) {
        var obj = document.getElementById("header");
        obj.style.backgroundColor='white';
        //obj.style.opacity='0.8';
        obj.style.color='black';
        obj.style.boxShadow='0px 2px 8px 0px rgba(23,58,147,0.1)';
        this.top = 1
      }
    },

  }
}
</script>

<style scoped>
.svg-icon {
  vertical-align: -0.15em;
  stroke: currentColor;
  overflow: hidden;
  fill: currentColor;
}
.liItem{
  margin:0 auto ;
  @apply w-6 mx-6 cursor-pointer
}
.inItem{
  background-color: #1E78FF;
  margin:0 auto ;border-color:#1E78FF;
  @apply w-6 mx-6 cursor-pointer border
}
</style>
