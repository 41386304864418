<template>
  <div class="w-full h-96 bg-transparent">
    <div class="h-96 bg-black flex justify-center">
      <div class="w-4/5 h-4/5 mt-12 justify-center">
        <div class="w-full h-4/5 space-x-12 flex">
          <div
            class="h-full w-1/4 grid grid-cols-2 justify-items-center items-center pl-20"
          >
            <!--      @click="goPage('/computePrice','3')"      -->
            <!--    @click="goPage('/helpInfo','4')"        -->
            <div
              class="text-white text-2xl text-center cursor-pointer"
              @click="goPage('/homeView', '1')"
            >
              首页
              <div
                :class="[
                  $store.state.menuIndex === '1' ? 'borderItem' : 'divItem',
                ]"
                style="margin-top: 2px"
              ></div>
            </div>
            <div
              class="text-white text-2xl text-center cursor-pointer"
              @click="goPage('/solvePlan', '2')"
            >
              方案
              <div
                :class="[
                  $store.state.menuIndex === '2' ? 'borderItem' : 'divItem',
                ]"
                style="margin-top: 2px"
              ></div>
            </div>
            <!--            <div  class="text-white text-2xl text-center cursor-pointer" >-->
            <!--              价格-->
            <!--              <div :class="[$store.state.menuIndex ==='4'?'borderItem':'divItem']" style="margin-top: 2px"></div>-->
            <!--            </div>-->
            <!--            <div class="text-white text-2xl text-center cursor-pointer" >-->
            <!--              帮助-->
            <!--              <div :class="[$store.state.menuIndex ==='4'?'borderItem':'divItem']" style="margin-top: 2px"></div>-->
            <!--            </div>-->
            <div
              class="text-white text-2xl text-center cursor-pointer"
              @click="goPage('/aboutUs', '5')"
            >
              关于
              <div
                :class="[
                  $store.state.menuIndex === '5' ? 'borderItem' : 'divItem',
                ]"
                style="margin-top: 2px"
              ></div>
            </div>
            <div
              class="text-white text-2xl text-center cursor-pointer"
              @click="goPage('/contactUs', '6')"
            >
              联系
              <div
                :class="[
                  $store.state.menuIndex === '6' ? 'borderItem' : 'divItem',
                ]"
                style="margin-top: 2px"
              ></div>
            </div>
          </div>
          <div class="h-full w-1/4 grid grid-cols-1 items-center pl-12">
            <div class="">
              <span
                class="text-white text-gray-500 w-10 float-left border-b border-gray-500"
                >版权</span
              >
              <span class="text-white float-left w-full"
                >深圳市华谕电子科技信息有限公司</span
              >
            </div>
            <div class="">
              <span
                class="text-white text-gray-500 w-10 float-left border-b border-gray-500"
                >地址</span
              >
              <span class="text-white float-left w-full"
                >深圳市南山区南头街道北环大道11008号</span
              >
              <span class="text-white float-left w-full"
                >豪方天际广场写字楼1201</span
              >
            </div>
            <div class="">
              <span
                class="text-white text-gray-500 w-20 float-left border-b border-gray-500"
                >联系方式</span
              >
              <span class="text-white float-left w-full"
                >13760250327 （微信同号）</span
              >
            </div>
          </div>
          <div class="h-full w-36 pt-8">
            <div class="mt-4 grid place-items-center">
              <img src="../assets/HomePics/vx.jpg" alt="华谕科技" />
              <span class="text-white text-sm mt-2">扫描添加企业微信</span>
            </div>
          </div>
          <div class="h-full w-56">
            <div class="mt-4 grid place-items-center">
              <img src="../assets/HomePics/LO.svg" alt="启迪协信" />
            </div>
          </div>
        </div>
        <div class="h-1/5 flex justify-center">
          <span class="text-white mt-10 text-sm text-gray-500">
            Copyright © 2017-2022 |
            <a href="https://beian.miit.gov.cn/#/Integrated/index">
              粤ICP备15106939号
            </a>
            | 经营许可证编号：A2.B1-20140071</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "webFooter",
  data() {
    return {};
  },
  watch: {},
  methods: {
    goPage(page, num) {
      this.$store.commit("setMenuIndex", num);
      this.$router.push(page);
      window.scrollTo(0, 0);
      if (num === "5" || num === "2" || num === "6") {
        this.$store.commit("setMenuColor", "white");
      } else {
        this.$store.commit("setMenuColor", "");
      }
    },
  },
};
</script>

<style scoped>
.divItem {
  margin: 0 auto;
  @apply w-6  cursor-pointer;
}
.borderItem {
  background-color: #1e78ff;
  margin: 0 auto;
  border-color: #1e78ff;
  @apply w-6  cursor-pointer border;
}
</style>
