import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/view/HomeView";




Vue.use(VueRouter)

const routes = [
    {
        path: '/HomeView',
        component: HomeView,
    },
    {
        path: '/',
        redirect: '/HomeView',
    },
    {
        path:'/aboutUs',
        component: resolve => require(['@/view/aboutUs'], resolve)
    },
    {
        path:'/contactUs',
        component: resolve => require(['@/view/contactUs'], resolve)
    },
    {
        path:'/solvePlan',
        name:'solvePlan',
        component: resolve => require(['@/view/solvePlan'], resolve)
    },
    {
        path:'/computePrice',
        component: resolve => require(['@/view/computePrice'], resolve)
    },
    {
        path:'/helpInfo',
        component: resolve => require(['@/view/helpInfo'], resolve)
    },

]
const router = new VueRouter({
    routes
})

export default router
