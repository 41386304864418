import {
  Button,
  Select,
  Form,
  FormItem,
    Card,
    Input,
    Carousel,
    CarouselItem,
    Radio,
    RadioButton,
    RadioGroup,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    InputNumber,
    Slider,
  Loading,
  Message
} from 'element-ui';

const element = {
  install: function(Vue) {
    Vue.use(Button);
    Vue.use(Select);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Card);
    Vue.use(Input);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.use(Radio);
    Vue.use(RadioButton);
    Vue.use(RadioGroup);
    Vue.use(Checkbox);
    Vue.use(CheckboxButton);
    Vue.use(CheckboxGroup);
    Vue.use(InputNumber);
    Vue.use(Slider);
    Vue.prototype.$message = Message;
    Vue.prototype.$loading = Loading.service;
  }
};
export default element;
