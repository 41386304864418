import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        menuIndex: "1",
        style: {color:''}
    },

    mutations:{
        // 设置菜单索引
        setMenuIndex(state, index){
            state.menuIndex = index;
        },
        setMenuColor(state, color){
            state.style.color = color;
        }
    }
})
