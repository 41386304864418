import Vue from 'vue'
import store from './store/index.js';
import App from './App.vue'
import 'tailwindcss/tailwind.css'
import element from './element/element.js'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import 'element-ui/lib/theme-chalk/base.css';
// import axios from 'axios';


Vue.config.productionTip = false
Vue.use(element);
// Vue.prototype.$http =axios

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
