<template>
  <div id="app" class="tablet:w-100 xl:w-full ">
    <transition name="slide-fade">
      <div v-show="showHeader">
          <web-header>
          </web-header>
      </div>
    </transition>

    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>

      <web-footer>
      </web-footer>
  </div>
</template>

<script>

import webHeader from "@/components/Header";
import webFooter from "@/components/Footer";

export default {
  components:{
    webHeader,
    webFooter
  },
  data(){
    return{
      showHeader:true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrolling) // 监听页面滚动
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
          document.body.scrollTop
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (scrollStep < 0|| scrollTop===0) {
        this.showHeader =true
      } else {
        this.showHeader =false
      }
    }
  }


}
</script>

<style scoped>
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition:all .4s;;
}
.fade-transform-enter {
  opacity: 0;
  transform: translateX(30px);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  {
  opacity: 0;
}
</style>
